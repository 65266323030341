import Router from 'vue-router'
import Vue from 'vue'
Vue.use(Router)

// 引入文件，动态路由
const Home = () => import("../components/Home.vue");
const FileUpload = () => import("../components/FileUpload.vue");
const FileSearch = () => import("../components/FileSearch.vue");
const FileSearchResult = () => import("../components/FileSearchResult.vue");

// 这里要注意一点，如下面这种写的话会报错，ts
// {
//    path: "/",
//    name: "Home",
//    component: () => import("../views/Home.vue")
//  },


const router = new Router({
    routes: [
        {
            path: "/home",
            name: "Home",
            component: Home,
        },
        {
            path: "/file-upload",
            name: "FileUpload",
            component: FileUpload,
        },
        {
            path: "/file-search",
            name: "FileSearch",
            component: FileSearch,
        },
        {
            path: "/file-search-result/:phone",
            name: "FileSearchResult",
            component: FileSearchResult,
            props: true
        },
    ]
})
// const routes = [
//     {
//         path: "/",
//         name: "Home",
//         component: Home,
//     },
//     {
//         path: "/file-upload",
//         name: "FileUpload",
//         component: FileUpload,
//     },
//     {
//         path: "/file-search", // 代替vue2的通配符path: "*",
//         name: "FileSearch",
//         component: FileSearch,
//     },
// ];

// const router = createRouter({
//     history: createWebHashHistory(),
//     routes
// })

export default router
