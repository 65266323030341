import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import { Upload, Button, Message, Input } from 'element-ui';

Vue.config.productionTip = false

Vue.component(Upload.name, Upload);
Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
Vue.prototype.$message = Message;

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
  withCredentials: true // 允许携带cookie
});

service.interceptors.request.use(
    config => {
      const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhdXRoU2VydmljZSIsImV4cCI6MjQ4OTA0NzU0MSwidXNlciI6eyJ1c2VySWQiOm51bGwsInd4VXNlcklkIjotOTk5LCJvcmdVc2VySWQiOm51bGwsImlzQWRtaW4iOm51bGwsIm9yZ2FuaXphdGlvbklkIjpudWxsfSwiaWF0IjoxNjI1MDQ3NTQxLCJqdGkiOiIyMTQ5ZTFjMi02ZTc3LTRlMjItODVjMC04MTgzNzlhNGQ5YmQifQ.PufvV1vpwRdoDP-n1iqLPgnLrw-tI2tqxuk6HYeP4ds'
      config.headers.Authorization = `Bearer ${token}`;
      return config
    }
)
Vue.prototype.$http = service;


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
